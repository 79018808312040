@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.fc-left {
	font-family: 'Raleway';

}
.fc-view-container {
	font-family: 'Gilroy';
  font-size: 14px;
  line-height: 20px;
}
.fc-head {
	font-weight: bold;
}
.fc-today-button.fc-button.fc-button-primary {
	background-color:#6636EF;
	border: none;
}
.fc-today-button.fc-button.fc-button-primary:disabled {
	background-color:#EFEFEF;
	border: none;
	opacity: 1;
}
.fc-prev-button.fc-button.fc-button-primary {
	background-color: #FFD02B;
	border-width: 0px 2px 0px 0px;
	border-color: white;
}
.fc-next-button.fc-button.fc-button-primary {
	background-color: #FFD02B;
	border: none;
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end {
	background-color: #6636EF;
	border: none;
}
.fc-day.fc-widget-content.fc-wed.fc-today {
	background-color: #EFEFEF;
}
.fc-toolbar.fc-header-toolbar {
	margin-bottom: 10px;
}
